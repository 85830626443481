/* eslint-disable react/no-unknown-property */
import CookieConsent from "@sid/core/components/CookieConsent";
import ErrorBoundary from "@sid/core/components/ErrorBoundary";
import ErrorPage from "@sid/core/components/ErrorPage";
import GAnalytics from "@sid/core/components/GAnalytics";
import { VerifyEmailEnforcementProvider } from "@sid/core/components/common/VerifyEmailEnforcement";
import AdsContextProvider from "@sid/core/context/AdsContext";
import AppContextProvider from "@sid/core/context/AppContext";
import { UserContextProvider } from "@sid/core/context/UserContext";
import "@sid/core/styles/globals.scss";
import { fetcher } from "@sid/core/util/api";
import { homeAlternate } from "@sid/core/vars/appAlternate";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import NextNProgress from "nextjs-progressbar";
import { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster } from "react-hot-toast";
import { SWRConfig } from "swr";
import colors from "tailwindcss/colors";
import transConf from "../next-i18next.config.js";

import Head from "next/head";

function MyApp({ Component, pageProps: { ...pageProps } }: AppProps<any>) {
  const { locale } = useRouter();

  return (
    <ErrorBoundary kind="USER">
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <MetatagRender />
      {pageProps.code >= 500 ? (
        <ErrorPage />
      ) : (
        <Fragment>
          <NextNProgress
            color={colors["red"][500]}
            startPosition={0.3}
            stopDelayMs={200}
            height={3}
            showOnShallow={true}
            options={{
              showSpinner: false,
            }}
          />
          <AppContextProvider
            value={{
              kind: "HOME",
              alternate: homeAlternate,
              disposableLink: pageProps?.disposableLink,
              protectedLink: pageProps?.protectedLink,
              verifyForm: pageProps?.verifyForm,
              ticketForm: pageProps?.ticketForm,
            }}
          >
            <SWRConfig
              value={{
                revalidateOnFocus: true,
                revalidateOnMount: true,
                refreshWhenHidden: false,
                fetcher: async (url) => await fetcher({ locale, url }),
              }}
            >
              <UserContextProvider>
                <AdsContextProvider ads={pageProps.ads || {}}>
                  <VerifyEmailEnforcementProvider>
                    <Component {...pageProps} />
                    <Toaster containerStyle={{ zIndex: 999999999 }} />
                  </VerifyEmailEnforcementProvider>
                </AdsContextProvider>
              </UserContextProvider>
            </SWRConfig>
          </AppContextProvider>
        </Fragment>
      )}
      <CookieConsent domain="sdotid.app" />
    </ErrorBoundary>
  );
}

const MetatagRender = () => {
  // const { pathname } = useRouter();
  return (
    <Fragment>
      <GAnalytics id="G-44RGXBDYQB" />
      {/* <GAnalytics id="G-98MWVCBDD7" />
      <GAnalytics id="AW-10823601447" />
      {pathname.startsWith("/dashboard") && <GAnalytics id="G-64WXGMF6D9" />}
      {!(
        pathname.startsWith("/preview") ||
        pathname.startsWith("/dashboard/invoices/detail") ||
        pathname.startsWith("/widget") ||
        pathname.startsWith("/security")
      ) && (
        <>
          <Script
            id="ze-snippet"
            async
            src="https://static.zdassets.com/ekr/snippet.js?key=4b27aa03-d3da-43eb-8382-660c054fbc9d"
            strategy="afterInteractive"
          />
        </>
      )} */}
    </Fragment>
  );
};

export default appWithTranslation(MyApp, transConf);
